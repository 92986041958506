@use "sass:map";
@use "sass:math";
@use "sass:list";

$spacing: 1rem;
$spacers: (
    -3: $spacing * -1,
    -2: math.div($spacing * -1, 2),
    -1: math.div($spacing * -1, 4),
    0: 0,
    1: math.div($spacing, 4),
    2: math.div($spacing, 2),
    3: $spacing,
    4: $spacing * 1.5,
    5: $spacing * 2,
    6: $spacing * 3,
    "100perc": 100%,
);
$micro-spacers: (
    -2: $spacing * -2,
    -1:  $spacing * -1,
    "-six-fifth": $spacing * -1.2,
    "-four-fifth": $spacing * -.8,
    "-two-fifth": $spacing * -.4,
    "-one-fifth": $spacing * -.2,
    "-seven-eighth": $spacing * -.775,
    "-half": math.div($spacing * -1, 2),
    "-fourth": math.div($spacing * -1, 4),
    "-eighth": math.div($spacing * -1, 8),
    "-twelfth": math.div($spacing * -1, 12),
    0: 0,
    "twelfth": math.div($spacing, 12),
    "eighth": math.div($spacing, 8),
    "fourth": math.div($spacing, 4),
    "half": math.div($spacing, 2),
    "one-fifth": $spacing * .2,
    "two-fifth": $spacing * .4,
    "four-fifth": $spacing * .8,
    "six-fifth": $spacing * 1.2,
    "thirty-times": $spacing * 13.2,
    1: $spacing,
    2: $spacing * 2,
);
$theming-colors: (
    "primary": $primary-passive-main,
    "secondary": $primary-active-main,
    "black": $black, // @TODO propose to change the intent from "black" to "dark"
    "white": $white, // @TODO propose to change the intent from "white" to "light"
);
$utilities: () !default;
$utilities: map.merge(
    (
        "display": (
            property: display,
            class: d,
            values: contents inline inline-block block grid table table-row table-cell flex inline-flex -webkit-box none
        ),
        "flex-direction": (
            property: flex-direction,
            class: flex,
            values: row row-reverse column column-reverse
        ),
        "flex-wrap": (
            property: flex-wrap,
            class: flex-wrap,
            values: wrap nowrap wrap-reverse
        ),
        "align-items": (
            property: align-items,
            class: align-items,
            values: (
                start: flex-start,
                end: flex-end,
                center: center,
                baseline: baseline,
                stretch: stretch,
                between: space-between,
            )
        ),
        "justify-content": (
            property: justify-content,
            class: justify-content,
            values: (
                start: flex-start,
                end: flex-end,
                center: center,
                between: space-between,
                around: space-around,
                evenly: space-evenly,
            )
        ),
        "gap": (
            property: gap,
            class: gap,
            values: $spacers
        ),
        "height": (
            property: height,
            class: h,
            values: (
                100: 100%
            )
        ),
        "width": (
            property: width,
            class: w,
            values: (
                25: 25%,
                50: 50%,
                75: 75%,
                100: 100%
            )
        ),
        "margin": (
            property: margin,
            class: m,
            values: map.merge($spacers, (auto: auto))
        ),
        "margin-x": (
            property: margin-right margin-left,
            class: mx,
            values: map.merge($spacers, (auto: auto))
        ),
        "margin-y": (
            property: margin-top margin-bottom,
            class: my,
            values: map.merge($spacers, (auto: auto))
        ),
        "margin-top": (
            property: margin-top,
            class: mt,
            values: map.merge($spacers, (auto: auto))
        ),
        "margin-right": (
            property: margin-right,
            class: mr,
            values: map.merge($spacers, (auto: auto))
        ),
        "margin-bottom": (
            property: margin-bottom,
            class: mb,
            values: map.merge($spacers, (auto: auto))
        ),
        "margin-left": (
            property: margin-left,
            class: ml,
            values: map.merge($spacers, (auto: auto))
        ),
        "max-width": (
            property: max-width,
            class: mw,
            values: fit-content
        ),
        "opacity": (
            property: opacity,
            class: opacity,
            values: (
                0: 0,
                25: .25,
                50: .5,
                75: .75,
                100: 1
            )
        ),
        "padding": (
            property: padding,
            class: p,
            values: $spacers
        ),
        "padding-x": (
            property: padding-right padding-left,
            class: px,
            values: $spacers
        ),
        "padding-y": (
            property: padding-top padding-bottom,
            class: py,
            values: $spacers
        ),
        "padding-top": (
            property: padding-top,
            class: pt,
            values: $spacers
        ),
        "padding-right": (
            property: padding-right,
            class: pr,
            values: $spacers
        ),
        "padding-bottom": (
            property: padding-bottom,
            class: pb,
            values: $spacers
        ),
        "padding-left": (
            property: padding-left,
            class: pl,
            values: $spacers
        ),
        "color": (
            property: color,
            class: text,
            values: $theming-colors
        ),
        "text-decoration": (
            property: text-decoration,
            class: text-decoration,
            values: underline
        ),
        "text-align": (
            property: text-align,
            class: text,
            values: (
                left: left,
                center: center,
                right: right,
            )
        ),
        "word-break": (
            property: word-break,
            class: word-break,
            values: (
                word: break-word,
                all: break-all,
            )
        ),
        "overflow": (
            property: overflow,
            class: overflow,
            values: auto visible hidden clip scroll
        ),
        "overflow-y": (
            property: overflow-y,
            class: overflow-y,
            values: auto visible hidden clip scroll
        ),
        "position": (
            property: position,
            class: position,
            values: absolute relative sticky fixed
        ),
        "bottom": (
            property: bottom,
            class: bottom,
            values: $micro-spacers
        ),
        "top": (
            property: top,
            class: top,
            values: $micro-spacers
        ),
        "left": (
            property: left,
            class: left,
            values: $micro-spacers
        ),
        "right": (
            property: right,
            class: right,
            values: $micro-spacers
        ),
        "overflow-wrap": (
            property: overflow-wrap,
            class: ow,
            values: "break-word"
        ),
        "inline-size": (
            property: inline-size,
            class: is,
            values: (
                100: 100px
            )
        ),
        "cursor": (
            property: cursor,
            class: cursor,
            values: default move not-allowed pointer help
        ),
        "break-before": (
            property: break-before,
            class: break-before,
            values: always auto avoid left right page column avoid-page avoid-column
        ),
    ),
    $utilities
);

// Loop over each utility property
@each $key, $utility in $utilities {
    $values: map.get($utility, values);

    // If the values are a list or string, convert it into a map
    @if type-of($values) == "string" or type-of(nth($values, 1)) != "list" {
        $values: list.zip($values, $values);
    }

    @each $key, $value in $values {
        $properties: map.get($utility, property);
        $class: map.get($utility, class);

        // Multiple properties are possible, for example with margins or paddings
        @if type-of($properties) == "string" {
            $properties: list.append((), $properties);
        }

        .#{$class + '-' + $key} {
            @each $property in $properties {
                #{$property}: $value !important;
            }
        }
    }
}

.font-number {
    font-family: Arial, Helvetica, sans-serif !important;
}
